#physicalTherapySection {
  .service-icon-wrapper {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: 100px; // Adjust based on your preference
    height: 100px; // Adjust based on your preference
    margin-bottom: 1rem; // Adds some space between the image and the title
    background-color: #f0f0f0; // Optional: Adds a background color in case the image doesn't load
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Adds a subtle shadow for depth
  }

  .service-icon {
    width: 100%;
    height: 100%;
    object-fit: cover; // Ensures the images cover the area without distorting aspect ratio
  }

  .service-title {
    font-size: 16px; // Reduced font size for service titles
    margin-top: 0.5rem; // Adds some space above the title if needed
    // You can also adjust other properties like font-weight, color etc. as per your design
  }

  @media (max-width: 767px) {
    .service-icon-wrapper {
      width: 80px; // Smaller size on mobile
      height: 80px; // Smaller size on mobile
    }
  }

  // Responsive layout adjustments
  @media (max-width: 991px) {
    .row {
      .col-md-6 {
        max-width: 50%; // Ensures two services per row on medium devices
        flex: 0 0 50%;
      }
    }
  }

  // Adjustments for larger screens to ensure service images are closer together
  @media (min-width: 992px) { // For desktops and larger devices
    .row {
      margin-right: 0; // Adjusts the right margin
      margin-left: 0; // Adjusts the left margin

      .col-lg-4 {
        padding-right: 15px; // Adjust this value to control spacing on the right
        padding-left: 15px; // Adjust this value to control spacing on the left
      }
    }
  }
}

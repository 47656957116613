
/*** Spinner Start ***/
/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}
/*** Spinner End ***/

@keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .slide-up-animation {
    animation: slideUp 0.5s ease-out forwards;
  }
  
  
  

.back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: flex;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    z-index: 99;
}

/*** Button Start ***/
.btn {
    font-weight: 600;
    transition: .5s;
}

.btn-square {
    width: 32px;
    height: 32px;
}

.btn-sm-square {
    width: 34px;
    height: 34px;
}

.btn-md-square {
    width: 44px;
    height: 44px;
}

.btn-lg-square {
    width: 56px;
    height: 56px;
}

.btn-square,
.btn-sm-square,
.btn-md-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50%;
}

.btn-lg-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px; /* Adjust as needed */
    height: 50px; /* Adjust as needed */
    border-radius: 50%; /* Make it circular */
    position: fixed; /* Keep it fixed during scroll */
    bottom: 20px; /* Position from the bottom */
    right: 20px; /* Position from the right */
    display: none; /* Start with it not displayed */
}

.btn.btn-primary {
    box-shadow: inset 0 0 0 0 var(--bs-primary);
}

.btn.btn-primary:hover {
    box-shadow: inset 300px 0 0 0 var(--bs-light) !important;
    color: var(--bs-primary) !important;
}

.btn.btn-light {
    box-shadow: inset 0 0 0 0 var(--bs-primary);
}

.btn.btn-light:hover {
    box-shadow: inset 300px 0 0 0 var(--bs-primary);
    color: var(--bs-light) !important;
}

.btn-hover {
    transition: 0.5s;
}

.btn-hover:hover {
    color: var(--bs-secondary) !important;
}

/*** Section Title Start ***/
.section-title {
    max-width: 900px;
    text-align: center;
    margin: 0 auto;
}

.section-title .sub-style {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: var(--bs-primary);
}

.section-title .sub-style::before {
    content: "";
    width: 100px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-top: 8px;
    margin-left: -100px;
    border: 1px solid var(--bs-primary) !important;
}

.section-title .sub-style::after {
    content: "";
    width: 50px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-bottom: 5px;
    margin-left: -50px;
    border: 1px solid var(--bs-primary) !important;
}

.sub-title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: var(--bs-primary);
}

.sub-title::before {
    content: "";
    width: 100px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-top: 8px;
    margin-right: -100px;
    border: 1px solid var(--bs-primary) !important;
}

.sub-title::after {
    content: "";
    width: 50px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-bottom: 8px;
    margin-right: -50px;
    border: 1px solid var(--bs-primary) !important;
}

/*** Hero Section ***/
.hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  
  .hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Dark tint for the background */
  }
  
  .hero-section .container {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  
  .hero-section h1 {
    font-size: 4rem; /* Font size increased for larger screens */
    color: #fff;
    margin-bottom: 0; /* Removes bottom margin to reduce gap between text and typed text */
  }
  
  .hero-section .typed-text {
    color: var(--bs-primary); /* Primary theme color for the typed text */
    font-size: 4rem; /* 64px equivalent, assuming the base font size is 16px */
    display: inline; /* Keep on the same line */
    font-family: 'Playfair Display', serif; /* Sets the font family to Playfair Display */
  }
  
  /* Adjusting the cursor size */
  .typed-cursor {
    font-size: 4rem; /* Match the typed text size */
    color: #ffffff; /* Primary theme color for the cursor */
  }
  
  
  .hero-section a.btn {
    margin-top: 2rem; /* Adds space above the button */
    color: #fff; /* Button text color */
    border-color: var(--bs-primary); /* Button border color */
    background-color: var(--bs-primary); /* Button background color */
  }
  
  @media (max-width: 768px) {
    .hero-section h1, .hero-section .typed-text, .typed-cursor {
      font-size: 2rem; /* Adjusts font size for smaller screens */
    }
  }
/*** Hero Section End ***/

/*** Topbar Start ***/
.fixed-top .container {
    transition: 0.5s;
}

.topbar {
    padding: 2px 10px 2px 20px;
    background: var(--bs-primary) !important;
}

.topbar a,
.topbar a i {
    transition: 0.5s;
}

.topbar a:hover,
.topbar a i:hover {
    color: var(--bs-secondary) !important;
}


@media (max-width: 576px) {
    .topbar {
        display: none;    
    }
}
/*** Topbar End ***/



/*** Navbar ***/

.navbar-container {
    transition: all 0.3s ease-in-out; /* Smooth transition for all changes */
}
  
  /* When navbar becomes sticky */
.fixed-top {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1020; /* Ensure it's above other content */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow for depth */
}

.navbar-light .navbar-nav .nav-link {
    font-family: 'Open Sans', sans-serif;
    position: relative;
    margin-right: 25px;
    padding: 35px 0;
    color: var(--bs-dark) !important;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    transition: .5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
    padding: 20px 0;
    color: var(--dark) !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
    color: var(--bs-primary) !important;
}

.navbar-light .navbar-brand img {
    max-height: 50px;
    transition: .5s;
}

.sticky-top.navbar-light .navbar-brand img {
    max-height: 45px;
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    vertical-align: middle;
    margin-left: 8px;
}

@media (min-width: 1200px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        border: 0;
        border-radius: 10px;
        transition: .5s;
        opacity: 0;
    }
}

.dropdown .dropdown-menu a:hover {
    background: var(--bs-primary);
    color: var(--bs-white);
}

.navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    background: var(--bs-light) !important;
    transition: .5s;
    opacity: 1;
}

@media (max-width: 991.98px) {
    .sticky-top.navbar-light {
        position: relative;
        background: var(--bs-white);
    }

    .navbar.navbar-expand-lg .navbar-toggler {
        padding: 10px 20px;
        border: 1px solid var(--bs-primary);
        color: var(--bs-primary);
    }

    .navbar-light .navbar-collapse {
        margin-top: 15px;
        border-top: 1px solid #DDDDDD;
    }

    /* Add this to reduce the font size of the company name */
    .navbar-light .navbar-brand h1 {
        font-size: 24px; /* Adjust based on your preference */
    }

    .navbar-light .navbar-nav .nav-link,
    .sticky-top.navbar-light .navbar-nav .nav-link {
        padding: 10px 0;
        margin-left: 0;
        color: var(--bs-dark) !important;
    }

    .navbar-light .navbar-brand img {
        max-height: 45px;
    }
}

@media (min-width: 992px) {

    .navbar .navbar-container {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .navbar-light {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        z-index: 999;
        /* height: 80px; Explicit height to ensure consistency */
        border-bottom: 1px solid rgba(255, 255, 255, .1);
        transition: all 0.3s ease-in-out; /* Smooth transition for all properties */
    }
    
    .sticky-top.navbar-light {
        position: fixed;
        background: var(--bs-light) !important;
        transition: all 0.3s ease-in-out; /* Smooth transition */
        box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Consistent with non-sticky state */
    }

    .navbar-light .navbar-nav .nav-link::before {
        position: absolute;
        content: "";
        width: 0;
        height: 2px;
        bottom: -1px;
        left: 50%;
        background: var(--bs-primary);
        transition: .5s;
    }

    .navbar-light .navbar-nav .nav-link:hover::before,
    .navbar-light .navbar-nav .nav-link.active::before {
        width: calc(100% - 2px);
        left: 1px;
    }

    .navbar-light .navbar-nav .nav-link.nav-contact::before {
        display: none;
    }
}

/*** Navbar End ***/

/*** Single Page Hero Header Start ***/
.bg-breadcrumb {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/carousel-2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 160px 0 60px 0;
}

.bg-breadcrumb .breadcrumb-item a {
    color: var(--bs-white) !important;
}
/*** Single Page Hero Header End ***/


/*** Service Start ***/
.service .service-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .2) ;
}

.service .service-item .service-img {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.service .service-item .service-img img {
    transition: 0.5s;
}

.service .service-item .service-img::before {
    width: 100%;
    height: 0;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    transition: 0.5s;
    z-index: 5;
    
}

.service .service-item:hover .service-img::before {
    height: 100%;
    background: rgba(21, 185, 217, .3);
}

.service .service-item .service-img:hover img {
    transform: scale(1.3);
}

.service .service-item .service-content {
    position: relative;
    z-index: 2;

}

.service .service-item .service-content::before {
    width: 100%;
    height: 8px;
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: rgba(21, 185, 217, .5);
    transition: 0.5s;
    z-index: 3;
}

.service .service-item:hover .service-content::before {
    background: rgba(21, 185, 217, .5);
    height: 100%;
}

.service .service-item .service-content .service-content-inner {
    transition: 0.5s;
}


.service .service-item:hover .service-content .service-content-inner {
    position: relative;
    color: var(--bs-white) !important;
    z-index: 9;
}
.service .service-item:hover .service-content .service-content-inner h5 {
    color: var(--bs-secondary);
}

/*** Service End ***/

/*** About Start ***/
.about .about-img {
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: 10px;
}

.about .about-img .about-img-inner {
    position: absolute;
    left: 0; 
    bottom: 0; 
    border: 10px solid; 
    border-color: var(--bs-white) var(--bs-white) var(--bs-white) var(--bs-white);
    border-radius: 50%;

}

.about .about-img .about-experience {
    position: absolute;
    top: 125px; 
    left: -125px; 
    transform: rotate(-90deg);
    background: transparent;
    color: var(--bs-primary);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 4px;
}

/*** About End ***/

/*** Feature Start ***/
.feature .feature-item {
    position: relative;
    display: flex;
    border: 1px solid var(--bs-primary);
    border-radius: 10px;
    background: var(--bs-light);
    transition: 0.5s;

}

.feature .feature-item::before {
    width: 0;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    transition: 0.5s;
}

.feature .feature-item:hover::before {
    width: 100%;
    background: var(--bs-primary);
}

.feature .feature-item .feature-icon {
    display: inline-flex;
    border-radius: 10px;
    transition: 0.5s;
}

.feature .feature-item:hover .feature-icon {
    position: relative;
    z-index: 2;
}

.feature .feature-item:hover .feature-content {
    position: relative;
    color: var(--bs-white);
    z-index: 2;
}

.feature .feature-item:hover .feature-content h5 {
    color: var(--bs-dark);
}
/*** Feature End ***/


/*** Appointment Start ***/
.appointment {
    background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(../img/carousel-2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.appointment .appointment-form {
    background: rgba(239, 162, 134, 0.3);
}

.appointment .appointment-form .btn.btn-primary {
    box-shadow: inset 0 0 0 0 var(--bs-primary);
}

.appointment .appointment-form .btn.btn-primary:hover {
    box-shadow: inset 600px 0 0 0 var(--bs-light) !important;
    color: var(--bs-primary) !important;
}
/*** Youtube Video start ***/
.video {
    position: relative;
}

.video .btn-play {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: content-box;
    display: block;
    width: 33px;
    height: 44px;
    border-radius: 50%;
    transition: 0.5s;
    

}

.video .btn-play:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 115px;
    height: 115px;
    background: var(--bs-primary);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
    transition: 0.5s;
}

.video .btn-play:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 100px;
    height: 100px;
    background: var(--bs-secondary);
    border-radius: 50%;
    transition: all 300ms;
}

.video .btn-play span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 33px solid var(--bs-white);
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    margin-left: 5px;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

#videoModal {
    z-index: 99999;
}

#videoModal .modal-dialog {
    position: relative;
    max-width: 800px;
    margin: 60px auto 0 auto;
}

#videoModal .modal-body {
    position: relative;
    padding: 0px;
}

#videoModal .close {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    top: -30px;
    z-index: 999;
    font-size: 30px;
    font-weight: normal;
    color: #ffffff;
    background: #000000;
    opacity: 1;
}
/*** Youtube Video End ***/
/*** Appointment End ***/


/*** Team Start ***/
.team .team-item .team-img {
    position: relative;
    overflow: hidden;
}

.team .team-item .team-img::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    transition: 0.5s;
}

.team .team-item .team-img .team-icon {
    position: absolute;
    bottom: -125px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.5s;
}

.team .team-item .team-img:hover .team-icon {
    margin-bottom: 145px;
}

.team .team-item:hover .team-img::before {
    background: rgba(21, 185, 217, .3);
}

.team .team-item .team-content {
    color: var(--bs-primary);
    transition: 0.5s;
}

.team .team-item .team-content h5 {
    color: var(--bs-secondary);
    transition: 0.5s;
}

.team .team-item:hover .team-content h5 {
    color: var(--bs-dark);
}

.team .team-item:hover .team-content {
    background: var(--bs-primary);
    color: var(--bs-white);
}
/*** Team End ***/


/*** testimonial Start ***/
.testimonial {
    background: linear-gradient(rgba(21, 185, 217, 0.9), rgba(21, 185, 217, 0.9)), url(../img/carousel-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.testimonial .testimonial-item .testimonial-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    background: transparent;
}

.testimonial .testimonial-item .testimonial-inner .testimonial-inner-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid var(--bs-white);
}

.testimonial .testimonial-carousel.owl-carousel {
    position: relative;
}

.testimonial .testimonial-carousel .owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: var(--bs-light);
    color: var(--bs-primary);
    transition: 0.5s;
}

.testimonial .testimonial-carousel .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background: var(--bs-light);
    color: var(--bs-primary);
    transition: 0.5s;
}


.testimonial .testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial .testimonial-carousel .owl-nav .owl-next:hover {
    box-shadow: inset 100px 0 0 0 var(--bs-primary) !important;
    color: var(--bs-white) !important;
}


@media (max-width: 900px) {
    .testimonial .testimonial-carousel .owl-nav .owl-prev {
        margin-top: -190px;
        margin-left: 40px;
    }

    .testimonial .testimonial-carousel .owl-nav .owl-next {
        margin-top: -190px;
        margin-right: 40px;
    }
    
}

.testimonial-carousel .owl-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.testimonial-carousel .owl-dots .owl-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 15px;
    border: 1px solid var(--bs-white);
    background: transparent;
    transition: 0.5s;
}

.testimonial-carousel .owl-dots .owl-dot.active {
    width: 20px;
    height: 20px;
    background: var(--bs-secondary) !important;
}

.testimonial-carousel .owl-dots .owl-dot span {
    position: relative;
    margin-top: 50%;
    margin-left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.testimonial-carousel .owl-dots .owl-dot span::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1px;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: var(--bs-white);
}

.testimonial .testimonial-carousel .testimonial-item .testimonial-inner p.fs-7 {
    transition: 0.5s;
}

@media (min-width: 900px) {
    .testimonial .testimonial-carousel .testimonial-item .testimonial-inner p.fs-7 {
        font-size: 20px;
    }
}


/* Testimonial Section Title Start */
.testimonial .section-title {
    max-width: 900px;
    text-align: center;
    margin: 0 auto;
}

.testimonial .section-title .sub-style {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: var(--bs-primary);
}

.testimonial .section-title .sub-style::before {
    content: "";
    width: 100px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-top: 8px;
    margin-left: -100px;
    border: 1px solid var(--bs-white) !important;
}

.testimonial .section-title .sub-style::after {
    content: "";
    width: 50px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-bottom: 5px;
    margin-left: -50px;
    border: 1px solid var(--bs-white) !important;
}

.testimonial .section-title .sub-title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: var(--bs-primary);
}

.testimonial .section-title .sub-title::before {
    content: "";
    width: 100px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-top: 8px;
    margin-right: -100px;
    border: 1px solid var(--bs-white) !important;
}

.testimonial .section-title .sub-title::after {
    content: "";
    width: 50px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-bottom: 8px;
    margin-right: -50px;
    border: 1px solid var(--bs-white) !important;
}
/*** Testimonial End ***/


/*** Blog Start ***/
.blog .blog-item .blog-img {
    position: relative;
    overflow: hidden;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.blog .blog-item .blog-img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    transition: 0.5s;
}

.blog .blog-item .blog-img img {
    transition: 0.5s;
}

.blog .blog-item .blog-img:hover img {
    transform: scale(1.3);
}

.blog .blog-item:hover .blog-img::after {
    background: rgba(21, 185, 217, .5);
}

.blog .blog-item .blog-centent {
    background: var(--bs-light);
    border: 1px solid var(--bs-primary);
    border-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
/*** Blog End ***/


/*** Contact Start ***/
.contact {
    background: linear-gradient(rgba(21, 185, 217, 0.9), rgba(21, 185, 217, 0.9)), url(../img/carousel-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}


.contact .contact-form .btn.btn-light {
    box-shadow: inset 0 0 0 0 var(--bs-primary);
}

.contact .contact-form .btn.btn-light:hover {
    box-shadow: inset 600px 0 0 0 var(--bs-primary) !important;
    color: var(--bs-white) !important;
}

.contact .contact-form .form-floating input,
.contact .contact-form .form-floating textarea,
.contact .contact-form .form-floating label {
    color: var(--bs-light);
}



/* Testimonial Section Title Start */
.contact .section-title {
    max-width: 900px;
    text-align: center;
    margin: 0 auto;
}

.contact .section-title .sub-style {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: var(--bs-primary);
}

.contact .section-title .sub-style::before {
    content: "";
    width: 100px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-top: 8px;
    margin-left: -100px;
    border: 1px solid var(--bs-white) !important;
}

.contact .section-title .sub-style::after {
    content: "";
    width: 50px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-bottom: 5px;
    margin-left: -50px;
    border: 1px solid var(--bs-white) !important;
}

.contact .section-title .sub-title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: var(--bs-primary);
}

.contact .section-title .sub-title::before {
    content: "";
    width: 100px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-top: 8px;
    margin-right: -100px;
    border: 1px solid var(--bs-white) !important;
}

.contact .section-title .sub-title::after {
    content: "";
    width: 50px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin-bottom: 8px;
    margin-right: -50px;
    border: 1px solid var(--bs-white) !important;
}
/*** Contact End ***/


/*** Footer Start ***/
.footer {
    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(../img/carousel-2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.footer .footer-item a {
    line-height: 35px;
    color: var(--bs-body);
    transition: 0.5s;
}

.footer .footer-item p {
    line-height: 35px;
}

.footer .footer-item a:hover {
    letter-spacing: 2px;
    color: var(--bs-primary);
}

/*** Footer End ***/

/*** copyright Start ***/
.copyright {
    background: var(--bs-dark) !important;
}
/*** copyright end ***/